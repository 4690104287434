/*
global getBodyOffsetTop TRANSITION_DURATION BODY_ELEMENT CATEGORIES_ELEMENT CATEGORIES PRODUCTIONS PAGES Vimeo
*/

class Production {
  constructor(_element) {
    const element = _element;
    let id; // eg. spielproduction/dolores
    let category; // instance of Category
    let title;
    let vimeoId;
    let productionsContainerElement;
    let thumbElement;
    let thumbLinkElement;
    let headerElement;
    let informationElement;
    let videoContainerElement;
    let bttnToggleInformationElement;
    let player;

    try {
      id = element.dataset.id;
      title = element.dataset.title;
      vimeoId = element.dataset.vimeoid;
      category = this.getCategory(element);
      thumbElement = category.element.querySelector(`.thumb[data-hrefId="${id}"]`);
      thumbLinkElement = thumbElement.querySelector('.thumb__link');
      productionsContainerElement = category.element.querySelector('.PRODUCTIONS-container');
      videoContainerElement = element.querySelector('.production__video-container');
      bttnToggleInformationElement = element.querySelector('.bttn-toggle-production-information');
      headerElement = element.querySelector('.production__header');
      informationElement = element.querySelector('.production__information');
      thumbLinkElement.addEventListener('click', (event) => this.onThumbLinkClick(event));
      bttnToggleInformationElement.addEventListener('click', (event) => this.onToggleInformationClick(event));
    } catch (exception) {
      console.error(exception);
    }

    const vimeoOptions = {
      id: parseInt(vimeoId),
      byline: false,
      portrait: false,
      title: false,
      color: 'ffffff',
    };

    this.id = id;
    this.category = category;
    this.title = title;
    this.vimeoOptions = vimeoOptions;
    this.player = player;
    this.element = element;
    this.thumbLinkElement = thumbLinkElement;
    this.productionsContainerElement = productionsContainerElement;
    this.videoContainerElement = videoContainerElement;
    this.bttnToggleInformationElement = bttnToggleInformationElement;
    this.headerElement = headerElement;
    this.informationElement = informationElement;
  }

  getCategory(_element) {
    const element = _element;
    let parent;
    let category;
    let categoryElement;
    let categoryId;

    function getParent() {
      parent = parent.parentElement;
      if (parent.classList.contains('category')) {
        return parent;
      }
      getParent();
    }

    try {
      parent = element.parentElement;
      categoryElement = getParent();
    } catch (exception) {
      console.error(exception);
    }

    categoryId = categoryElement.dataset.id;

    CATEGORIES.forEach((_category) => {
      if (_category.id === categoryId) {
        category = _category;
      }
    });

    return category;
  }

  getCurrentVisibleProduction() {
    let currentVisibleProduction;
    PRODUCTIONS.forEach((production) => {
      if (!production.element.hidden) {
        currentVisibleProduction = production;
      }
    });
    return currentVisibleProduction;
  }

  initPlayer() {
    const { element } = this;
    const { videoContainerElement } = this;
    const iframeElement = videoContainerElement.querySelector('iframe');
    if (typeof this.player === 'undefined') {
      if (klaro?.getManager().states.vimeo === true) {
        this.player = new Vimeo.Player(iframeElement);
      }
      this.player?.on('timeupdate', function (data) {
        if (element.hidden) {
          console.log('pause because hidden');
          this.pause();
        }
      });
    }
  }

  hide() {
    const { element } = this;
    const { thumbLinkElement } = this;
    const categoryElement = this.category.element;
    if (element.offsetHeight > 0) {
      if (categoryElement.offsetTop >= getBodyOffsetTop()) {
        element.style.height = `${element.offsetHeight}px`;
        element.offsetHeight;
        element.style.height = '0px';
        element.style.opacity = '0';
        setTimeout(() => {
          element.hidden = true;
          element.style.height = '';
          element.style.opacity = '';
          element.style.position = '';
          this.hideInformation();
        }, 500);
      } else {
        BODY_ELEMENT.scrollTop = getBodyOffsetTop() - element.offsetHeight;
        element.hidden = true;
        element.style.position = '';
        this.hideInformation();
      }
      if (this.player) {
        this.player.setVolume(0);
        setTimeout(() => {
          this.player.setCurrentTime(0);
          this.player.setVolume(1);
          this.player.pause();
        }, 500);
      }
      thumbLinkElement.classList.remove('is-active');
    }
  }

  show() {
    const { element } = this;
    const { thumbLinkElement } = this;
    const categoryElement = this.category.element;
    const currentVisibleProduction = this.getCurrentVisibleProduction();
    const currentVisiblePage = PAGES[0].getCurrentVisiblePage();

    let scrollDuration = Math.abs(document.body.scrollTop - categoryElement.offsetTop);
    scrollDuration = scrollDuration > 1000 ? 1000 : scrollDuration;

    this.initPlayer();
    // if (window.klaro) {
    //   window.klaro.watch((obj, name, data) => {
    //     console.log(obj, name, data);
    //   });
    // }

    if (typeof currentVisiblePage === 'undefined') {
      scrollTo(categoryElement.offsetTop, scrollDuration, 'easeOutQuad', () => {
        let offsetHeight = 0;
        if (typeof currentVisibleProduction !== 'undefined'
          && currentVisibleProduction.category === this.category) {
          const currentVisibleProductionElement = currentVisibleProduction.element;
          currentVisibleProductionElement.style.position = 'absolute';
          element.style.opacity = '0';
          element.hidden = false;
          offsetHeight = element.offsetHeight;
          element.style.height = `${currentVisibleProductionElement.offsetHeight}px`;
        } else {
          element.style.opacity = '0';
          element.style.position = 'absolute';
          element.hidden = false;
          offsetHeight = element.offsetHeight;
          element.style.position = '';
          element.style.height = '0px';
        }
        element.offsetHeight;
        element.style.height = `${offsetHeight}px`;
        if (typeof currentVisibleProduction !== 'undefined') {
          currentVisibleProduction.hide();
        }
        setTimeout(() => {
          BODY_ELEMENT.scrollTop = categoryElement.offsetTop; // chrome fix
        }, 100);
        setTimeout(() => {
          element.style.opacity = '1';
          element.style.height = '';
          element.style.position = '';
          setTimeout(() => {
            if (!element.hidden && !BODY_ELEMENT.classList.contains('is-touch-device')) {
              this.player?.play();
            }
          }, 500);
        }, 500);
        this.category.show();
      });
    } else {
      currentVisiblePage.element.style.opacity = '0';
      setTimeout(() => {
        currentVisiblePage.hide();
        element.hidden = false;
        CATEGORIES_ELEMENT.hidden = false;
        CATEGORIES_ELEMENT.offsetWidth;
        BODY_ELEMENT.scrollTop = categoryElement.offsetTop;
        CATEGORIES_ELEMENT.style.opacity = '1';
        this.category.show();
        if (!element.hidden) {
          this.player?.play();
        }
      }, TRANSITION_DURATION * 4);
    }
    thumbLinkElement.classList.add('is-active');
  }

  showInformation() {
    const { informationElement } = this;
    const { bttnToggleInformationElement } = this;
    let offsetHeight = 0;
    informationElement.style.opacity = '0';
    informationElement.style.position = 'absolute';
    informationElement.hidden = false;
    offsetHeight = informationElement.offsetHeight;
    informationElement.style.position = '';
    informationElement.style.height = '0px';
    informationElement.offsetHeight;
    informationElement.style.opacity = '';
    informationElement.style.height = `${offsetHeight}px`;
    bttnToggleInformationElement.setAttribute('aria-expanded', 'true');
    setTimeout(() => {
      informationElement.style.height = '';
    }, 500);
  }

  hideInformation() {
    const { informationElement } = this;
    const { bttnToggleInformationElement } = this;
    informationElement.style.height = `${informationElement.offsetHeight}px`;
    informationElement.offsetHeight;
    informationElement.style.height = '0px';
    informationElement.style.opacity = '0';
    setTimeout(() => {
      this.informationElement.hidden = true;
      informationElement.style.height = '';
      informationElement.style.opacity = '';
    }, 500);
    bttnToggleInformationElement.setAttribute('aria-expanded', 'false');
  }

  toggleInformation() {
    const { informationElement } = this;
    if (informationElement.hidden) {
      this.showInformation();
    } else {
      this.hideInformation();
    }
  }

  onThumbLinkClick(event) {
    const { element } = this;
    event.preventDefault();
    if (element.hidden) {
      history.pushState(this.id, this.title, `/${this.id}`);
      document.title = this.title;
      this.show();
    }
  }

  onToggleInformationClick() {
    this.toggleInformation();
  }
}

export default Production;
