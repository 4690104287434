/*
globals isTouchDevice CATEGORIES MAIN_ELEMENT
*/

class Start {
  constructor() {
    const element = document.querySelector('.start');
    let videoElement;
    let mainnavElement;
    let mainnavBttnToggleElement;
    let mainnavItemElements;
    let mainnavItemHomeElement;

    const self = this;

    if (element) {
      videoElement = element.querySelector('.start__video');
      mainnavElement = document.querySelector('.mainnav');
      mainnavBttnToggleElement = mainnavElement.querySelector('.bttn-toggle-mainnav');
      mainnavItemElements = mainnavElement.querySelectorAll('.mainnav__item:not(.mainnav__item--home)');
      mainnavItemHomeElement = mainnavElement.querySelector('.mainnav__item--home');
    }

    function init() {
      Array.from(mainnavItemElements).forEach((item) => {
        item.style.transitionDuration = '0s';
        item.style.transform = 'translateX(-2rem)';
        item.style.opacity = '0';
      });
      element.style.opacity = '0';
      // element.style.transform = 'scale(1.01)';
      // element.style.filter = 'blur(10px)';
      mainnavBttnToggleElement.style.transitionDuration = '0s';
      mainnavBttnToggleElement.style.opacity = '0';
      mainnavElement.style.transitionDuration = '0s';
      mainnavElement.hidden = false;
      mainnavElement.classList.add('mainnav--start');
      mainnavItemHomeElement.style.transitionDuration = '0s';
      mainnavItemHomeElement.style.left = 'calc(50% - 6.25rem)';
      mainnavItemHomeElement.style.opacity = '0';
      mainnavItemHomeElement.style.transform = 'scale(1.02)';
      mainnavElement.offsetWidth;
      mainnavElement.style.transitionDuration = '';
      mainnavItemHomeElement.style.transitionDuration = '2s';
      mainnavItemHomeElement.style.opacity = '';
      mainnavItemHomeElement.style.transform = '';
      mainnavBttnToggleElement.style.transitionDuration = '';
      document.querySelector('html').style.overflow = 'hidden';

      videoElement.addEventListener('canplaythrough', () => self.onCanPlayThrough());
      mainnavItemHomeElement.querySelector('.mainnav__link')
        .addEventListener('click', (event) => self.onMainnavItemHomeClick(event));

      if (isTouchDevice()) {
        videoElement.play();
      }

      setTimeout(() => {
        if (self.isStartVisible) {
          element.style.opacity = '1';
          // element.style.transform = '';
          // element.style.filter = '';
          setTimeout(() => {
            if (self.isStartVisible) {
              self.showStart();
              setTimeout(() => {
                if (!self.isVideoLoaded && self.isStartVisible) {
                  self.hideStart();
                }
              }, 5000);
            }
          }, 1000);
        }
      }, 5000);

      // show content if no click, after 10 seconds
      // setTimeout(() => {
      //   if (!element.hidden) {
      //     self.hideStart();
      //   }
      // }, 10000);
    }

    this.element = element;
    this.videoElement = videoElement;
    this.mainnavElement = mainnavElement;
    this.mainnavBttnToggleElement = mainnavBttnToggleElement;
    this.mainnavItemElements = mainnavItemElements;
    this.mainnavItemHomeElement = mainnavItemHomeElement;
    this.isStartVisible = true;
    this.isVideoLoaded = false;

    if (element && !element.hidden) {
      init();
    }
  }

  moveLogoUp() {
    const { mainnavElement } = this;
    mainnavElement.classList.remove('mainnav--start');
  }

  showStart() {
    const { element } = this;
    const { mainnavItemHomeElement } = this;
    element.style.opacity = '1';
    setTimeout(() => {
      mainnavItemHomeElement.style.transitionDuration = '';
      mainnavItemHomeElement.style.left = '';
      setTimeout(() => {
        this.showMainnavItems();
      }, 1000);
    }, 2000);
    this.isStartVisible = true;
  }

  hideStart() {
    const { element } = this;
    const { mainnavBttnToggleElement } = this;
    const { mainnavItemElements } = this;
    const { mainnavItemHomeElement } = this;
    this.hideMainnavItems();
    MAIN_ELEMENT.style.display = '';
    MAIN_ELEMENT.style.opacity = '0';
    mainnavItemHomeElement.style.transitionDuration = '';
    mainnavItemHomeElement.style.left = '';
    document.querySelector('html').style.overflow = '';
    this.isStartVisible = false;
    setTimeout(() => {
      element.style.opacity = '0';
      this.moveLogoUp();
      setTimeout(() => {
        MAIN_ELEMENT.style.opacity = '';
        element.hidden = true;
        mainnavBttnToggleElement.style.opacity = '';
        this.showMainnavItems();
        setTimeout(() => {
          Array.from(mainnavItemElements).forEach((item) => {
            item.style.transitionDelay = '0s';
          });
        }, 800);
      }, 1200);
    }, 1000);
  }

  showMainnavItems() {
    const { mainnavItemElements } = this;
    Array.from(mainnavItemElements).forEach((item, index) => {
      item.style.transitionDuration = '0s';
      item.style.transform = 'translateX(-2rem)';
      item.style.transitionDelay = `${50 * index}ms`;
      item.offsetWidth;
      item.style.transitionDuration = '';
      item.style.transform = '';
      item.style.opacity = '';
    });
  }

  hideMainnavItems() {
    const { mainnavItemElements } = this;
    Array.from(mainnavItemElements).forEach((item) => {
      item.style.opacity = '0';
    });
    setTimeout(() => {
      Array.from(mainnavItemElements).forEach((item) => {
        item.style.transform = 'translateX(-2rem)';
      });
    }, 700);
  }

  onCanPlayThrough() {
    if (this.isStartVisible) {
      this.videoElement.play();
      this.showStart();
      this.isVideoLoaded = true;
    }
  }

  onMainnavItemHomeClick(event) {
    if (!this.element.hidden) {
      event.preventDefault();
      this.hideStart();
    }
  }
}

export default Start;
