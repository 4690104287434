/*
global getBodyElement getBodyOffsetTop scrollTo isTouchDevice Start Page Category Production Biography Mainnav
*/

import './polyfills/_arrayFrom.js';
import './helper/_getBodyElement.js';
import './helper/_getBodyOffsetTop.js';
import './helper/_scrollTo.js';
import './helper/_isTouchDevice.js';
import Start from './_Start.js';
import Page from './_Page.js';
import Category from './_Category.js';
import Production from './_Production.js';
import Biography from './_Biography.js';
import Mainnav from './_Mainnav.js';

window.TRANSITION_DURATION = 250;

window.PAGES = [];
window.CATEGORIES = [];
window.PRODUCTIONS = [];
window.BIOGRAPHIES = [];

window.BODY_ELEMENT = getBodyElement();
window.CATEGORIES_ELEMENT = document.querySelector('.categories');
window.MAIN_ELEMENT = document.querySelector('.main');

window.START = new Start();
window.MAINNAV = new Mainnav();

function init() {
  if (PRODUCTIONS.length > 0 && CATEGORIES.length > 0) {
    const currentVisibleProduction = PRODUCTIONS[0].getCurrentVisibleProduction();
    const currentVisibleCategory = CATEGORIES[0].getCurrentVisibleCategory();
    let scrollTop = 0;
    if (typeof currentVisibleProduction !== 'undefined') {
      scrollTop = currentVisibleProduction.category.element.offsetTop;
      document.body.scrollTop = scrollTop;
      currentVisibleProduction.initPlayer();
    } else if (typeof currentVisibleCategory !== 'undefined') {
      scrollTop = currentVisibleCategory.element.offsetTop;
      document.body.scrollTop = scrollTop;
    }
    if (isTouchDevice()) {
      BODY_ELEMENT.classList.add('is-touch-device');
    }
  }
}

function showItem() {
  PRODUCTIONS.forEach((production) => {
    if (production.id === location.pathname.substr(1)) {
      production.show();
    }
  });
  CATEGORIES.forEach((category) => {
    if (category.id === location.pathname.substr(1)) {
      category.show();
    }
  });
  PAGES.forEach((page) => {
    if (page.id === location.pathname.substr(1)) {
      page.show();
    }
  });
}

function hideItem() {
  PRODUCTIONS.forEach((production) => {
    production.hide();
  });
  CATEGORIES.forEach((category) => {
    category.hide();
  });
  PAGES.forEach((page) => {
    page.hide();
  });
  CATEGORIES_ELEMENT.hidden = false;
  CATEGORIES_ELEMENT.style.opacity = '';
}

Array.from(document.querySelectorAll('.page')).forEach((element) => {
  PAGES.push(new Page(element));
});

Array.from(document.querySelectorAll('.category')).forEach((element) => {
  CATEGORIES.push(new Category(element));
});

Array.from(document.querySelectorAll('.production')).forEach((element) => {
  PRODUCTIONS.push(new Production(element));
});

Array.from(document.querySelectorAll('.biography')).forEach((element) => {
  BIOGRAPHIES.push(new Biography(element));
});

window.addEventListener('popstate', (event) => {
  if (event.state === null) {
    hideItem();
  } else {
    showItem();
  }
});

setTimeout(() => {
  init();
}, 100);

/*
font.com tracking script.
*/
const MTIProjectId = '571b103c-17d2-4a17-8be7-3a51ed81bc29';
(function () {
  const mtiTracking = document.createElement('script');
  mtiTracking.type = 'text/javascript';
  mtiTracking.async = 'true';
  mtiTracking.src = '/assets/js/mtiFontTrackingCode.js';
  (document.getElementsByTagName('head')[0] || document.getElementsByTagName('body')[0]).appendChild(mtiTracking);
}());
