/*
global TRANSITION_DURATION BODY_ELEMENT CATEGORIES_ELEMENT MAINNAV PAGES BIOGRAPHIES CATEGORIES PRODUCTIONS
*/

class Page {
  constructor(_element) {
    const element = _element;
    const mainnavLinkProductionsElement = document.querySelector('.mainnav__item--productions .mainnav__link');

    let id;
    let title;
    let mainnavLinkElement;

    try {
      id = element.dataset.id;
      title = element.dataset.title;
      const mainnavItemElement = document.querySelector(`.mainnav__item[data-id=${id}]`);
      mainnavLinkElement = mainnavItemElement.querySelector('.mainnav__link');
      mainnavLinkElement.addEventListener('click', (event) => this.onMainnavLinkClick(event));
    } catch (exception) {
      console.error(exception);
    }

    this.id = id;
    this.title = title;
    this.element = element;
    this.mainnavLinkElement = mainnavLinkElement;
    this.mainnavLinkProductionsElement = mainnavLinkProductionsElement;
  }

  getCurrentVisiblePage() {
    let currentVisiblePage;
    PAGES.forEach((page) => {
      if (!page.element.hidden) {
        currentVisiblePage = page;
      }
    });
    return currentVisiblePage;
  }

  show() {
    const { element } = this;
    const { mainnavLinkElement } = this;
    const { mainnavLinkProductionsElement } = this;
    const currentVisiblePage = this.getCurrentVisiblePage();
    let elementToHide;

    if (!CATEGORIES_ELEMENT.hidden) {
      elementToHide = CATEGORIES_ELEMENT;
      CATEGORIES.forEach((category) => {
        category.hide();
      });
      setTimeout(() => {
        PRODUCTIONS.forEach((production) => {
          production.hide();
        });
      }, 1000);
    } else if (typeof currentVisiblePage !== 'undefined') {
      elementToHide = currentVisiblePage.element;
      currentVisiblePage.mainnavLinkElement.classList.remove('is-active');
    }

    elementToHide.style.opacity = '0';
    element.style.opacity = '0';
    setTimeout(() => {
      BODY_ELEMENT.scrollTop = 0;
      elementToHide.hidden = true;
      element.hidden = false;
      element.offsetWidth;
      element.style.opacity = '1';
      mainnavLinkElement.classList.add('is-active');
      PRODUCTIONS.forEach((production) => {
        production.hide();
      });
    }, TRANSITION_DURATION * 4);

    mainnavLinkProductionsElement.classList.remove('is-active');

    MAINNAV.hide();
  }

  hide() {
    const { mainnavLinkElement } = this;
    const { element } = this;
    mainnavLinkElement.classList.remove('is-active');
    element.hidden = true;
    BIOGRAPHIES.forEach((biography) => {
      biography.hideText();
    });
  }

  onMainnavLinkClick(event) {
    const { mainnavLinkElement } = this;
    event.preventDefault();
    if (!mainnavLinkElement.classList.contains('is-active')) {
      history.pushState(this.id, this.title, `/${this.id}`);
      document.title = this.title;
      this.show();
    }
  }
}

export default Page;
