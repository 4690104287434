/*
global getBodyOffsetTop TRANSITION_DURATION BODY_ELEMENT CATEGORIES_ELEMENT MAIN_ELEMENT START CATEGORIES PRODUCTIONS PAGES
*/

class Category {
  constructor(_element) {
    const element = _element;
    let id;
    let title;
    let mainnavLinkElement;

    try {
      id = element.dataset.id;
      title = element.dataset.title;
      const mainnavItemElement = document.querySelector(`.mainnav__item[data-id=${id}]`);
      mainnavLinkElement = mainnavItemElement.querySelector('.mainnav__link');
      mainnavLinkElement.addEventListener('click', (event) => this.onMainnavLinkClick(event));
    } catch (exception) {
      console.error(exception);
    }

    this.id = id;
    this.title = title;
    this.element = element;
    this.mainnavLinkElement = mainnavLinkElement;
  }

  getCurrentVisibleCategory() {
    let currentVisibleCategory;
    CATEGORIES.forEach((category) => {
      if (category.mainnavLinkElement.classList.contains('is-active')) {
        currentVisibleCategory = category;
      }
    });
    return currentVisibleCategory;
  }

  hideOthers(_exceptCategory) {
    CATEGORIES.forEach((category) => {
      if (_exceptCategory !== category) {
        category.hide();
      }
    });
  }

  showFirstProduction() {
    const productions = [];
    let firstProduction;
    PRODUCTIONS.forEach((production) => {
      if (production.category === this) {
        productions.push(production);
      }
    });
    firstProduction = productions[0];
    history.pushState(firstProduction.id, firstProduction.title, `/${firstProduction.id}`);
    document.title = firstProduction.title;
    firstProduction.show();
  }

  show() {
    const currentVisiblePage = PAGES[0].getCurrentVisiblePage();
    let scrollDuration = 0;
    if (typeof currentVisiblePage !== 'undefined') {
      currentVisiblePage.element.style.opacity = '0';
      setTimeout(() => {
        currentVisiblePage.hide();
        CATEGORIES_ELEMENT.style.opacity = '0';
        CATEGORIES_ELEMENT.hidden = false;
        CATEGORIES_ELEMENT.offsetWidth;
        CATEGORIES_ELEMENT.style.opacity = '1';
        BODY_ELEMENT.scrollTop = this.element.offsetTop;
      }, TRANSITION_DURATION * 4);
    } else {
      scrollDuration = Math.abs(getBodyOffsetTop() - this.element.offsetTop);
      scrollDuration = scrollDuration > 1000 ? 1000 : scrollDuration;
      this.hideOthers(this);
      scrollTo(this.element.offsetTop, scrollDuration, 'easeOutQuad', () => {
        PRODUCTIONS.forEach((production) => {
          if (this !== production.category) {
            production.hide();
          }
        });
      });
    }
    this.mainnavLinkElement.classList.add('is-active');
  }

  hide() {
    this.mainnavLinkElement.classList.remove('is-active');
  }

  onMainnavLinkClick(event) {
    let scrollDuration = 0;
    event.preventDefault();
    if (START.element && !START.element.hidden) {
      START.hideStart();
      this.showFirstProduction();
    } else if (!this.mainnavLinkElement.classList.contains('is-active')) {
      history.pushState(this.id, this.title, `/${this.id}`);
      document.title = this.title;
      this.show();
    } else {
      PRODUCTIONS.forEach((production) => {
        production.hide();
      });
      history.pushState(this.id, this.title, `/${this.id}`);
      document.title = this.title;
      scrollDuration = Math.abs(getBodyOffsetTop() - this.element.offsetTop);
      scrollDuration = scrollDuration > 500 ? 500 : scrollDuration;
      scrollTo(this.element.offsetTop, scrollDuration, 'easeOutQuad');
    }
  }
}

export default Category;
