/*
global BIOGRAPHIES
*/

class Biography {
  constructor(_element) {
    const element = _element;
    let textElement;
    let bttnToggleElement;

    try {
      textElement = element.querySelector('.biography__text');
      bttnToggleElement = element.querySelector('.bttn-toggle-biografie');
      bttnToggleElement.addEventListener('click', () => this.toggleBiography());
    } catch (exception) {
      console.log(exception);
    }

    this.textElement = textElement;
    this.bttnToggleElement = bttnToggleElement;

    this.hideText();
  }

  hideOtherTexts(_except) {
    BIOGRAPHIES.forEach((biography) => {
      if (biography !== _except) {
        biography.hideText();
      }
    });
  }

  showText() {
    const { textElement } = this;
    const { bttnToggleElement } = this;
    let offsetHeight = 0;
    this.hideOtherTexts(this);
    textElement.style.opacity = '0';
    textElement.style.position = 'absolute';
    textElement.setAttribute('aria-hidden', 'false');
    offsetHeight = textElement.offsetHeight;
    textElement.style.position = '';
    textElement.style.height = '0px';
    textElement.offsetHeight;
    textElement.style.opacity = '';
    textElement.style.height = `${offsetHeight}px`;
    bttnToggleElement.setAttribute('aria-expanded', 'true');
    setTimeout(() => {
      textElement.style.height = '';
    }, 500);
  }

  hideText() {
    const { textElement } = this;
    const { bttnToggleElement } = this;
    textElement.style.height = `${textElement.offsetHeight}px`;
    textElement.offsetHeight;
    textElement.style.height = '0px';
    textElement.style.opacity = '0';
    setTimeout(() => {
      textElement.setAttribute('aria-hidden', 'true');
      textElement.style.height = '';
      textElement.style.opacity = '';
    }, 500);
    bttnToggleElement.setAttribute('aria-expanded', 'false');
  }

  toggleBiography() {
    const { textElement } = this;
    if (textElement.getAttribute('aria-hidden') === 'true') {
      this.showText();
    } else {
      this.hideText();
    }
  }
}

export default Biography;
