/*
globals CATEGORIES_ELEMENT BODY_ELEMENT PAGES PRODUCTIONS
*/

class Mainnav {
  constructor() {
    const element = document.querySelector('.mainnav');
    const bttnToggleElement = element.querySelector('.bttn-toggle-mainnav');
    const linkHomeElement = element.querySelector('.mainnav__item--home .mainnav__link');
    const linkProductionsElement = element.querySelector('.mainnav__item--productions .mainnav__link');
    const itemElements = element.querySelectorAll('.mainnav__item:not(.mainnav__item--home)');

    if (PAGES.length > 0) {
      setTimeout(() => {
        if (typeof PAGES[0].getCurrentVisiblePage() === 'undefined') {
          linkProductionsElement.classList.add('is-active');
        }
      }, 100);
    }

    bttnToggleElement.addEventListener('click', () => this.onBttnToggleClick());
    linkProductionsElement.addEventListener('click', (event) => this.onLinkProductionsClick(event));
    window.addEventListener('scroll', () => this.onScroll());

    this.element = element;
    this.bttnToggleElement = bttnToggleElement;
    this.linkHomeElement = linkHomeElement;
    this.itemElements = itemElements;
    this.linkProductionsElement = linkProductionsElement;
  }

  show() {
    const { element } = this;
    const { linkHomeElement } = this;
    const itemElementsArray = Array.from(this.itemElements);
    if (BODY_ELEMENT.scrollTop !== 0) {
      linkHomeElement.style.transitionDuration = '0s';
      linkHomeElement.style.opacity = '0';
    }
    itemElementsArray.forEach((item) => {
      item.style.transitionDuration = '0s';
      item.style.opacity = '0';
    });
    element.classList.add('is-visible');
    setTimeout(() => {
      linkHomeElement.style.transitionDuration = null;
      linkHomeElement.style.opacity = null;
      itemElementsArray.forEach((item) => {
        item.style.transitionDuration = null;
        item.style.opacity = null;
      });
    }, 250);
  }

  hide() {
    const { element } = this;
    const { linkHomeElement } = this;
    const itemElementsArray = Array.from(this.itemElements);
    if (window.matchMedia('(max-width: 63.75em)').matches) {
      setTimeout(() => {
        if (BODY_ELEMENT.scrollTop !== 0) {
          linkHomeElement.style.opacity = '0';
        }
        itemElementsArray.forEach((item) => {
          item.style.opacity = '0';
        });
        setTimeout(() => {
          element.style.backgroundColor = 'transparent';
          setTimeout(() => {
            element.style.backgroundColor = null;
            element.classList.remove('is-visible');
            linkHomeElement.style.opacity = null;
            itemElementsArray.forEach((item) => {
              item.style.opacity = null;
            });
          }, 250);
        }, 500);
      }, 250);
    }
  }

  onBttnToggleClick() {
    const { element } = this;
    if (element.classList.contains('is-visible')) {
      this.hide();
    } else {
      this.show();
    }
  }

  onLinkProductionsClick(event) {
    const { linkProductionsElement } = this;
    const { title } = linkProductionsElement.dataset;
    if (PAGES.length > 0 && CATEGORIES_ELEMENT) {
      event.preventDefault();
      BODY_ELEMENT.scrollTop = 0;
      PAGES.forEach((page) => {
        page.hide();
      });
      PRODUCTIONS.forEach((page) => {
        page.hide();
      });
      this.hide();
      CATEGORIES_ELEMENT.hidden = false;
      CATEGORIES_ELEMENT.offsetWidth;
      CATEGORIES_ELEMENT.style.opacity = '1';
      setTimeout(() => {
        linkProductionsElement.classList.add('is-active');
      }, 500);
      history.pushState(null, title, '/');
      document.title = title;
    }
  }

  onScroll() {
    const { bttnToggleElement } = this;
    if (BODY_ELEMENT.scrollTop > 7) {
      bttnToggleElement.classList.add('is-not-on-top');
    } else {
      bttnToggleElement.classList.remove('is-not-on-top');
    }
  }
}

export default Mainnav;
